import React, { Component } from 'react'
import LayoutContainer from '../containers/LayoutContainer';
import BlockForm from '../components/forms/BlockForm';

export default class BlockPage extends Component {
    render() {
        const { id } = this.props.match.params;
        return (
            <LayoutContainer>
                {id ? <h1>Editace textového bloku</h1> : <h1>Nový textový blok</h1>}

                <BlockForm id={id} />

            </LayoutContainer>
        )
    }
}

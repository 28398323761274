import React, { Component } from "react";
import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Card
} from "react-bootstrap";
import ApiService from "../../services/ApiService";
import { EnumsContext } from "../../contexts/enumsContext";
import { set, get } from "lodash";
import { CurrentUserContext } from "../../contexts/currentUserContext";
import Icon from "../common/Icon/Icon";
import { faSave } from "@fortawesome/free-solid-svg-icons";

export default class ProfileForm extends Component {
  state = {};

  handleChange = (key, value) => {
    const newState = set(this.state, key, value);
    this.setState(newState);
  };

  handleSubmit = (e, callback = null) => {
    e.preventDefault();
    ApiService.updateMe({
      config: this.state
    }).then(() => {
      console.log(callback);
      if (callback) callback();
    });
  };

  render() {
    const { theme } = this.state;
    return (
      <EnumsContext.Consumer>
        {ENUMS => (
          <CurrentUserContext.Consumer>
            {({ currentUser, reloadMe }) => {
              if (!theme && currentUser.config) {
                this.handleChange(
                  "theme",
                  get(currentUser, "config.theme", "light")
                );
              }

              return (
                <Card>
                  <Card.Body>
                    <Form onSubmit={e => this.handleSubmit(e, reloadMe)}>
                      <FormGroup>
                        <FormLabel>Vzhled</FormLabel>
                        <FormControl
                          as="select"
                          value={theme}
                          onChange={e =>
                            this.handleChange("theme", e.target.value)
                          }
                        >
                          {ENUMS.THEME_OPTIONS.map(theme => (
                            <option key={theme.value} value={theme.value}>
                              {theme.name}
                            </option>
                          ))}
                        </FormControl>
                      </FormGroup>
                      <div className="d-flex justify-content-between">
                        <div />
                        <Button variant="primary" type="submit">
                          <Icon icon={faSave} /> Uložit
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              );
            }}
          </CurrentUserContext.Consumer>
        )}
      </EnumsContext.Consumer>
    );
  }
}

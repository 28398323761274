import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";

import UsersPage from "./pages/UsersPage";
import UserPage from "./pages/UserPage";
import SettingsPage from "./pages/SettingsPage";
import BuilderPage from "./pages/BuilderPage";
import alertify from "alertifyjs";

import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/bootstrap.min.css";
import './App.css'

import BlocksPage from './pages/BlocksPage';
import BlockPage from './pages/BlockPage';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (localStorage.getItem("token")) return rest.render ? rest.render(props) : <Component {...props} />;
      alertify.notify("Pro pokračování se prosím přihlaste.");
      return <Redirect to="/login" />;
    }}
  />
);

class App extends Component {
  render() {
    return (
      <Router path={process.env.REACT_APP_API_URL || '/'}>
        <PrivateRoute path="/" exact component={DashboardPage} />
        <Route path="/login" exact component={LoginPage} />

        <PrivateRoute path='/builder' exact component={BuilderPage} />

        {/* <PrivateRoute path="/articles" exact component={ArticlesPage} />
        <PrivateRoute path="/articles/edit/:id" exact component={ArticlePage} />
        <PrivateRoute path="/articles/new" exact component={ArticlePage} /> */}

        <PrivateRoute path="/users" exact component={UsersPage} />
        <PrivateRoute path="/users/edit/:id" exact component={UserPage} />
        <PrivateRoute path="/users/new" exact component={UserPage} />

        <PrivateRoute path="/blocks" exact component={BlocksPage} />
        <PrivateRoute path="/blocks/edit/:id" exact component={BlockPage} />
        <PrivateRoute path="/blocks/new" exact component={BlockPage} />

        {/* <PrivateRoute path="/pages" exact component={FoodsPage} />
        <PrivateRoute path="/pages/edit/:id" exact component={FoodPage} />
        <PrivateRoute path="/pages/new" exact component={FoodPage} /> * /}

        {/* <PrivateRoute path="/foods" exact component={FoodsPage} />
        <PrivateRoute path="/foods/edit/:id" exact component={FoodPage} />
        <PrivateRoute path="/foods/new" exact render={(routeProps) => (
          <FoodPage {...routeProps} week={false} />
        )} /> */}

        {/* <PrivateRoute path="/foods/new-week" exact render={(routeProps) => (
          <FoodPage {...routeProps} week={true} />
        )} /> */}

        <PrivateRoute path="/settings" exact component={SettingsPage} />
      </Router>
    );
  }
}

export default App;

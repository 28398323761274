import React, { Component } from 'react'
import LayoutContainer from '../containers/LayoutContainer';
import UserForm from '../components/forms/UserForm';

export default class UserPage extends Component {
    render() {
        const { id } = this.props.match.params;
        return (
            <LayoutContainer>
                {id ? <h1>User Edit #{id}</h1> : <h1>New User</h1>}

                <UserForm id={id} />

            </LayoutContainer>
        )
    }
}

import React, { Component } from 'react'
import LayoutContainer from '../containers/LayoutContainer';
// import { Table, Row, Col } from 'react-bootstrap';
import ApiService from "../services/ApiService";
import alertify from "alertifyjs";
// import Icon from "../components/common/Icon/Icon";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";


export default class DashboardPage extends Component {
  state = {
    foods: [],
    weekly: [],
  };

  loadData = async () => {
    await ApiService.getFoodsToday()
      .then(res => res.data)
      .then(foods => this.setState({ foods }));
    await ApiService.getFoodsWeek()
      .then(res => res.data)
      .then(weekly => this.setState({ weekly }));
  };

  componentDidMount() {
    this.loadData();
  }

  handleDelete = (title, id) => {
    alertify.confirm(
      `${title} - #${id}`,
      `Opravdu chcete smazat toto jídlo?`,
      () => {
        ApiService.deleteFood(id).then(() => {
          this.loadData();
          alertify.success(`Jídlo [${title} - #${id}] byl smazán`);
        });
      },
      () => {
        alertify.notify("Operace přerušena");
      }
    );
  };

  render() {
    return (
      <LayoutContainer>

      </LayoutContainer>
    )
  }
}

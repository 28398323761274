import React from "react";
import ApiService from "../services/ApiService";
import Loader from "../components/common/Loader/Loader";

export const EnumsContext = React.createContext();

export class EnumsProvider extends React.Component {
  state = {
    loaded: false,
  };

  componentWillMount() {
    ApiService.getEnums()
      .then(res => res.data)
      .then(enums => this.setState({ ...enums, loaded: true }));
  }

  render() {
    const { loaded, ...restState } = this.state;
    return (
      <EnumsContext.Provider
        value={{
          ...restState
        }}
      >
        {loaded ? this.props.children : <Loader />}
      </EnumsContext.Provider>
    );
  }
}

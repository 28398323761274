import React, { Component } from "react";

import { Container, Row, Col } from "react-bootstrap";
import SideMenu from "../components/common/SideMenu/SideMenu";
import Header from "../components/common/Header/Header";
import { EnumsProvider } from "../contexts/enumsContext";
import { CurrentUserProvider } from "../contexts/currentUserContext";

export default class LayoutContainer extends Component {
  render() {
    return (
      <EnumsProvider>
        <CurrentUserProvider>
          <Header />
          <Container fluid>
            <Row>
              <Col md={3} className='py-3 bg-light sidebar'>
                <SideMenu />
              </Col>
              <Col md={9} className='py-3'>{this.props.children}</Col>
            </Row>
          </Container>
        </CurrentUserProvider>
      </EnumsProvider>
    );
  }
}

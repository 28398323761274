import React from "react";
import ApiService from "../services/ApiService";
import Loader from "../components/common/Loader/Loader";

export const CurrentUserContext = React.createContext();

export class CurrentUserProvider extends React.Component {
  state = { currentUser: {}, loading: false };

  componentWillMount() {
    this.loadMe();
  }

  loadMe = () => {
    new Promise(resolve => {
      this.setState(
        {
          loading: true
        },
        () => {
          ApiService.getMe()
            .then(res => res.data)
            .then(currentUser => {
              this.setState({ currentUser, loading: false }, () =>
                resolve(currentUser)
              );
            });
        }
      );
    });
  };

  render() {
    const { currentUser, loading } = this.state;
    return (
      <CurrentUserContext.Provider
        value={{
          currentUser,
          reloadMe: this.loadMe
        }}
      >
        {loading ? <Loader /> : this.props.children}
      </CurrentUserContext.Provider>
    );
  }
}

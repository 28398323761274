import React, { Component } from 'react'
import LayoutContainer from '../containers/LayoutContainer';
import { Table } from 'react-bootstrap';
import ApiService from '../services/ApiService';
import Icon from "../components/common/Icon/Icon";
import { faPencilAlt, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

export default class UsersPage extends Component {

    state = {
        users: [],
    }

    componentWillMount = () => {
        this.loadData()
    }


    loadData = () => {
        ApiService.getUsers()
            .then(res => res.data)
            .then(users => this.setState({ users }));
    }


    handleDelete = (title, id) => {
        //eslint-disable-next-line
        if (confirm(`Opravdu chcete smazat clanek ${title} ? #${id}`)) {
            ApiService.deleteUser(id).then(() => this.loadData())
        }
    }


    render() {

        const { users } = this.state;
        return (
            <LayoutContainer>
                <h1>Uživatelé</h1>

                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Uživatelské jméno</th>
                            <th>Akce</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (<tr key={user._id}>
                            <td>{user._id}</td>
                            <td>{user.username}</td>
                            <td>
                                <a href={`/users/edit/${user._id}`} className='btn btn-warning'>
                                    <Icon icon={faPencilAlt} />
                                </a>
                                <span className='btn btn-danger' onClick={() => this.handleDelete(user.username, user._id)}>
                                    <Icon icon={faTrash} />
                                </span>
                            </td>
                        </tr>))}
                    </tbody>
                </Table>


                <a className='btn btn-success' href='/users/new'>
                    <Icon icon={faPlus} />
                </a>

            </LayoutContainer>
        )
    }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
const uaParser = require("ua-parser-js");

export default class SessionUserAgent extends Component {
  getBrowserName = () => {
    return get(uaParser(this.props.userAgent), "browser.name", "-");
  };

  getOS = () => {
    return get(uaParser(this.props.userAgent), "os.name", "-");
  };

  getDevice = () => {
    return get(uaParser(this.props.userAgent), "device.model", "-");
  };

  render() {
    return (
      <>
        <div>Prohlížeč: {this.getBrowserName()}</div>
        <div>OS: {this.getOS()}</div>
        <div>Zařízení: {this.getDevice()}</div>
      </>
    );
  }
}
SessionUserAgent.propTypes = {
  userAgent: PropTypes.string.isRequired
};

import React, { Component } from "react";
import { Table } from "react-bootstrap";
import SessionTableRow from "./SessionTableRow";
import PropTypes from "prop-types";

export default class SessionTable extends Component {
  render() {
    const { sessions, handleReload } = this.props;
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Expirace</th>
              <th>O zařízení</th>
              <th>Poslední aktivita</th>
              <th>Akce</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map(session => (
              <SessionTableRow
                session={session}
                onRemove={handleReload}
                key={session._id}
              />
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

SessionTable.defaultProps = {
  handleReload: () => {}
};

SessionTable.propTypes = {
  handleReload: PropTypes.func
};

import React, { Component } from 'react'
import './LoginPage.css';
import { Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import LoginForm from '../components/forms/LoginForm';

export default class LoginPage extends Component {
    render() {
        const token = localStorage.getItem('token');
        if (token) return <Redirect to='/' />;
        return (
            <div className='bg-light login-container'>
                <Card body>
                    <LoginForm />
                </Card>
            </div>
        )
    }
}

import React, { Component } from "react";
import moment from "moment";
import ApiService from "../../../services/ApiService";
import PropTypes from "prop-types";
import SessionUserAgent from "./SessionUserAgent";
import TimeAgo from "react-timeago";
import czechStrings from "react-timeago/lib/language-strings/cs";
import { Button } from "react-bootstrap";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import Icon from "../../common/Icon/Icon";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

export default class SessionTableRow extends Component {
  handleRemove = () => {
    const {
      session: { _id },
      onRemove
    } = this.props;
    ApiService.deleteSession(_id).then(() => onRemove());
  };

  isSessionExpiring = () => {
    const {
      session: { expiresAt }
    } = this.props;
    const featureTime = moment().add(20, "minutes");
    return moment(expiresAt).isAfter(featureTime);
  };

  getSessionBackground = () => {
    const {
      session: { isCurrent }
    } = this.props;
    if (isCurrent) return "#2ecc71";
    if (this.isSessionExpiring()) return "#ecf0f1";
    else return "#bdc3c7";
  };

  render() {
    const {
      session: { userAgent, isCurrent, expiresAt, lastActivity }
    } = this.props;
    const style = {
      background: this.getSessionBackground()
    };
    const timeAgoFormatter = buildFormatter(czechStrings);

    return (
      <tr style={style}>
        <td>
          {expiresAt ? (
            <TimeAgo date={expiresAt} formatter={timeAgoFormatter} />
          ) : (
            "trvalé přihlášení"
          )}
        </td>
        <td>
          <SessionUserAgent userAgent={userAgent} />
        </td>
        <td>
          {isCurrent ? (
            "Nyní"
          ) : (
            <TimeAgo date={lastActivity} formatter={timeAgoFormatter} />
          )}
        </td>
        <td>
          {!isCurrent && (
            <Button onClick={this.handleRemove} variant="danger">
              <Icon icon={faSignOutAlt} />
            </Button>
          )}
        </td>
      </tr>
    );
  }
}

SessionTableRow.defaultProps = {
  onRemove: () => {}
};

SessionTableRow.propTypes = {
  onRemove: PropTypes.func
};

import React, { Component } from "react";

import { Form, Button } from "react-bootstrap";
import ApiService from "../../services/ApiService";

export default class UserForm extends Component {
  state = {
    user: {
      email: "",
      username: "",
      password: null,
      config: {
        profile: {
          theme: "light"
        }
      }
    }
  };

  componentWillMount = () => {
    const { id } = this.props;
    if (id) {
      ApiService.getUser(id)
        .then(res => res.data)
        .then(user => this.setState({ user }));
    }
  };

  handleChange = (key, value) => {
    this.setState({
      user: {
        ...this.state.user,
        [key]: value
      }
    });
  };

  handleSave = e => {
    e.preventDefault();
    const { id } = this.props;
    if (id) {
      /// aktualizace
      ApiService.udpateUser(id, this.state.user).then(
        () => (window.location.href = "/users")
      );
    } else {
      /// novy clanek
      ApiService.createUser(this.state.user).then(
        () => (window.location.href = "/users")
      );
    }
  };

  render() {
    const { email, username, password } = this.state.user;
    return (
      <Form onSubmit={this.handleSave}>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={e => this.handleChange("email", e.target.value)}
            placeholder="Enter email"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={e => this.handleChange("username", e.target.value)}
            placeholder="Enter username"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Email password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={e => this.handleChange("password", e.target.value)}
            placeholder="Enter password"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Ulozit
        </Button>
      </Form>
    );
  }
}

UserForm.defaultProps = {
  id: null
};

import React, { Component } from "react";
import LayoutContainer from "../containers/LayoutContainer";
import ApiService from "../services/ApiService";
import SessionTable from "../components/tables/SessionTable/SessionTable";
import ProfileForm from "../components/forms/ProfileForm";
import Icon from "../components/common/Icon/Icon";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

export default class SettingsPage extends Component {
  state = { sessions: [] };

  loadData = () => {
    ApiService.getUserSessions()
      .then(res => res.data)
      .then(sessions => this.setState({ sessions }));
  };

  componentWillMount() {
    this.loadData();
  }

  handleDeleteSessions = () => {
    ApiService.deleteSessions().then(() => this.loadData());
  };

  render() {
    const { sessions } = this.state;
    return (
      <LayoutContainer>
        <h3>Nastavení účtu</h3>
        <ProfileForm />

        <div className="d-flex justify-content-between align-items-center py-3">
          <h3>Aktuální přihlášení</h3>
          <button
            className="btn btn-primary"
            onClick={this.handleDeleteSessions}
          >
            <Icon icon={faSignOutAlt} /> Odhlásit na ostatních zařízeních
          </button>
        </div>

        <SessionTable sessions={sessions} handleReload={this.loadData} />
      </LayoutContainer>
    );
  }
}

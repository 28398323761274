
import React, { Component } from "react";

import { Form, Button, Col, Row, Container } from "react-bootstrap";
import ApiService from "../../services/ApiService";

// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Icon from "../common/Icon/Icon";
import { faSave } from "@fortawesome/free-solid-svg-icons";

// import Toggle from "react-bootstrap-toggle";
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";

export default class BlockForm extends Component {
  state = {
    block: {
      title: "",
      body: ""
    }
  };

  componentWillMount = () => {
    const { id } = this.props;
    if (id) {
      ApiService.getBlock(id)
        .then(res => res.data)
        .then(block => this.setState({ block }));
    }
  };

  handleChange = e => {
    this.setState({
      block: {
        ...this.state.block,
        [e.target.name]: e.target.value
      }
    });
  };

  handleSave = e => {
    e.preventDefault();
    const { id } = this.props;
    if (id) {
      /// aktualizace
      ApiService.udpateBlock(id, this.state.block).then(
        () => (window.location.href = "/blocks")
      );
    } else {
      /// novy clanek
      ApiService.createBlock(this.state.block).then(
        () => (window.location.href = "/blocks")
      );
    }
  };

  render() {
    const { block } = this.state;
    return (
      <Form onSubmit={this.handleSave}>
        <Container fluid>
          <Row>
            <Col>
              {this.props.id ? <Form.Control
                value={block.title}
                readOnly
                name='title'
                type="text"
                placeholder="Headline"
              /> : <Form.Control
                onChange={this.handleChange}
                value={block.title}
                name='title'
                type="text"
                placeholder="Headline"
              />}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                onChange={this.handleChange}
                value={block.body}
                name='body'
                type="text"
                placeholder="Text"
                rows={10}
                as="textarea"
              />
            </Col>
          </Row>
        </Container>

        <br />

        <div className="d-flex justify-content-between">
          <div />
          <Button variant="primary" type="submit">
            <Icon icon={faSave} /> Uložit
          </Button>
        </div>
      </Form>
    );
  }
}

BlockForm.defaultProps = {
  id: null
};

import "react-grid-layout-builder/dist/public/css/react-grid-layout-with-builder.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import React from "react";
import { Responsive } from "react-grid-layout";
import { connectReactGridLayoutBuilder } from "react-grid-layout-builder";
import * as _ from "lodash";
const uuidv1 = require("uuid/v1");
const WidthProvider = require("react-grid-layout").WidthProvider;
const ResponsiveReactGridLayout = connectReactGridLayoutBuilder(
  WidthProvider(Responsive)
);

export default class BuilderPage extends React.Component {
  state = {
    layouts: [],
    rowHeight: 50,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
  };

  generateDOM = () => {
    return _.map(this.state.layouts, function(l, i) {
      return <div key={i} className={l.static ? "bg-disabled" : "bg-light"} />;
    });
  };

  onLayoutChange = layouts => {
    this.setState({
      layouts: layouts.map(layout => ({ ...layout, id: uuidv1() }))
    });
  };

  componentWillMount() {
    this.addBox();
  }

  addBox = () =>
    this.setState({
      layouts: [
        ...this.state.layouts,
        {
          x: 12,
          y: 1,
          w: 12,
          h: 12,
          s: false,
          id: uuidv1(),
          i: "Box"
        }
      ]
    });

  generateLayout = () => {
    return _.map(_.range(0, 1), this.addBox);
  };

  render() {
    return (
      <div>
        <button onClick={this.addBox}>pridat</button>
        <ResponsiveReactGridLayout
          onLayoutChange={this.onLayoutChange}
          {...this.props.conf}
          updateConfigFunc={config => this.setState(config)}
        >
          {this.generateDOM()}
        </ResponsiveReactGridLayout>
        {JSON.stringify(this.state.layouts)}
      </div>
    );
  }
}

// function getFromLS(prop, key) {
//   let ls = {};
//   if (global.localStorage) {
//     try {
//       ls = JSON.parse(global.localStorage.getItem(key)) || {};
//     } catch (e) {}
//   }
//   return ls[prop];
// }

// function saveToLS(valueKey, value, key) {
//   if (global.localStorage) {
//     global.localStorage.setItem(
//       key,
//       JSON.stringify({
//         [valueKey]: value
//       })
//     );
//   }
// }

import axios from "axios";
import alertify from "alertifyjs";
import { get } from "lodash";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

const token = localStorage.getItem("token");

const apiConnection = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  crossDomain: true,
  headers: {
    authorization: token ? `Bearer ${token}` : undefined,
  }
});

apiConnection.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = get(error, "response.status", null);
    if (!status) {
      alertify.error("Nepodařilo se navázat spojení se serverem.");
      // localStorage.removeItem("token");
      return axios.request(error.config);
    }
    if (status === 401) {
      console.warn({ error });
      localStorage.removeItem("token");
    }
    return Promise.reject(error);
  }
);

export default {
  ping: () => apiConnection.get("/"),
  getEnums: () => apiConnection.get(`/enums`),

  getArticles: () => apiConnection.get("/articles"),
  getArticle: id => apiConnection.get(`/articles/${id}`),
  udpateArticle: (id, article) => apiConnection.put(`/articles/${id}`, article),
  createArticle: article => apiConnection.post(`/articles`, article),
  deleteArticle: id => apiConnection.delete(`/articles/${id}`),


  // getPages: () => apiConnection.get("/pages"),
  // getPage: id => apiConnection.get(`/pages/${id}`),
  // udpatePage: (id, article) => apiConnection.put(`/pages/${id}`, article),
  // createPage: article => apiConnection.post(`/pages`, article),
  // deletePage: id => apiConnection.delete(`/pages/${id}`),

  getBlocks: () => apiConnection.get("/blocks"),
  getBlock: id => apiConnection.get(`/blocks/${id}`),
  udpateBlock: (id, article) => apiConnection.put(`/blocks/${id}`, article),
  createBlock: article => apiConnection.post(`/blocks`, article),
  deleteBlock: id => apiConnection.delete(`/blocks/${id}`),

  getFoods: () => apiConnection.get("/foods"),
  getFoodsToday: () => apiConnection.get("/foods/today"),
  getFoodsWeek: () => apiConnection.get("/foods/week"),
  getFood: id => apiConnection.get(`/foods/${id}`),
  udpateFood: (id, article) => apiConnection.put(`/foods/${id}`, article),
  createFood: article => apiConnection.post(`/foods`, article),
  deleteFood: id => apiConnection.delete(`/foods/${id}`),

  getUsers: () => apiConnection.get("/users"),
  getUser: id => apiConnection.get(`/users/${id}`),
  getUserSessions: () => apiConnection.get(`/users/sessions`),
  udpateUser: (id, article) => apiConnection.put(`/users/${id}`, article),
  createUser: article => apiConnection.post(`/users`, article),
  deleteUser: id => apiConnection.delete(`/users/${id}`),

  deleteSession: id => apiConnection.delete(`/users/sessions/${id}`),
  deleteSessions: () => apiConnection.delete(`/users/sessions`),

  getMe: () => apiConnection.get(`/users/me`),
  updateMe: user => apiConnection.put(`/users/me`, user),

  doLogin: credentials => apiConnection.post(`/auth/token`, credentials),
  doLogout: () => apiConnection.delete(`/auth/token`),
  getNavigation: module => apiConnection.get(`/${module}/navigation`),
};

import React, { Component } from "react";
import LayoutContainer from "../containers/LayoutContainer";
import { Table } from "react-bootstrap";
import ApiService from "../services/ApiService";
import alertify from "alertifyjs";
import Icon from "../components/common/Icon/Icon";
import { faPencilAlt, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

export default class BlocksPage extends Component {
  state = {
    blocks: []
  };

  componentWillMount = () => {
    this.loadData();
  };

  loadData = () => {
    return ApiService.getBlocks()
      .then(res => res.data)
      .then(blocks => this.setState({ blocks }));
  };

  handleDelete = (title, id) => {
    alertify.confirm(
      `${title} - #${id}`,
      `Opravdu chcete smazat tento blok?`,
      () => {
        ApiService.deleteBlock(id).then(() => {
          this.loadData();
          alertify.success(`Blok [#${id}] byl smazán`);
        });
      },
      () => {
        alertify.notify("Operace přerušena");
      }
    );
  };

  render() {
    const { blocks } = this.state;
    return (
      <LayoutContainer>
        <h1>Blocks</h1>

        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Identity</th>
              <th>Text</th>
              <th>Akce</th>
            </tr>
          </thead>
          <tbody>
            {blocks.map(block => (
              <tr key={block._id}>
                <td>{block.title}</td>
                <td>{block.body}</td>
                <td>
                  <a
                    href={`/blocks/edit/${block._id}`}
                    className="btn btn-warning"
                  >
                    <Icon icon={faPencilAlt} />
                  </a>
                  {/* <span
                    className="btn btn-danger"
                    onClick={() =>
                      this.handleDelete(block.title, block._id)
                    }
                  >
                    <Icon icon={faTrash} />
                  </span> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <a className="btn btn-success" href="/blocks/new">
          <Icon icon={faPlus} />
        </a>
      </LayoutContainer>
    );
  }
}

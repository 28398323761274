import React, { Component } from "react";

import { Form, Button } from "react-bootstrap";
import ApiService from "../../services/ApiService";
import alertify from "alertifyjs";

export default class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    rememberMe: false
  };

  handleChange = (key, value) => this.setState({ [key]: value });

  handleLogin = e => {
    e.preventDefault();

    const { email, password, rememberMe } = this.state;

    ApiService.doLogin({ email, password, rememberMe })
      .then(res => res.data)
      .then(({ token }) => {
        localStorage.setItem("token", token);
        window.location.href = "/";
        alertify.success("Přihlášen");
      })
      .catch(err => {
        alertify.error("Neplatné přihlašovací údaje. Zkuzte to prosím znovu.");
        this.setState({ password: "" });
      });
  };

  render() {
    const { email, password, rememberMe } = this.state;
    return (
      <Form onSubmit={this.handleLogin}>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="text"
            value={email}
            onChange={e => this.handleChange("email", e.target.value)}
            placeholder="email or username"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={e => this.handleChange("password", e.target.value)}
            placeholder="Password"
          />
        </Form.Group>

        <Form.Group controlId="formBasicChecbox">
          <Form.Check
            type="checkbox"
            label="Trvalé přihlášení"
            value={rememberMe}
            onChange={() => this.handleChange("rememberMe", !rememberMe)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    );
  }
}

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { CurrentUserContext } from "../../../contexts/currentUserContext";
import { get } from "lodash";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon/Icon";
import ApiService from "../../../services/ApiService";

export default class Header extends Component {
  handleLogout = e => {
    e.preventDefault();
    e.stopPropagation();
    ApiService.doLogout().then(() => {
      localStorage.removeItem("token");
      window.location.href = "/login";
    });
  };

  render() {
    return (
      <CurrentUserContext.Consumer>
        {({ currentUser }) => {
          const theme = get(currentUser, "config.theme", "light");
          const textClass = theme === "dark" ? "text-white" : "text-black";
          return (
            <Container
              fluid
              className={`bg-${theme ? theme : "light"} ${textClass} py-3`}
            >
              <Container fluid className="d-flex justify-content-between align-items-center">
                <h1>Administrace</h1>
                <div>
                  {get(currentUser, "email", "")} |{" "}
                  <a href="/logout" role="button" onClick={this.handleLogout}>
                    <Icon icon={faSignOutAlt} />
                  </a>
                </div>
              </Container>
            </Container>
          );
        }}
      </CurrentUserContext.Consumer>
    );
  }
}

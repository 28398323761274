import React, { Component } from "react";

import { Nav } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";

class SideMenu extends Component {
  render() {
    return (
      <Nav className="flex-column">
        <NavLink to="/">Nástěnka</NavLink>
        <NavLink to="/blocks">Textové bloky</NavLink>
        <NavLink to="/users">Uživatelé</NavLink>
        <hr />
        <NavLink to="/settings">Nastavení</NavLink>
      </Nav>
    );
  }
}

class NavLink extends Component {
  render() {
    return <Link className="nav-link" role="button" {...this.props} />;
  }
}

export default withRouter(SideMenu);
